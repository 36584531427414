<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        .content
          div(
            v-for="(part, partIdx) in $t('company.content')"
            :key="'company#part#'+partIdx")
            p(v-if="part.title")
              strong(v-html="part.title")
            p(
              v-for="(text, textIdx) in part.text"
              :key="'company#part#'+partIdx+'text#'+textIdx"
              v-html="text")

          div(
            v-for="(part, partIdx) in $t('contacts.content')"
            :key="'contacts#part#'+partIdx")
            p(v-if="part.title")
              strong(v-html="part.title")
            p(
              v-for="(text, textIdx) in part.text"
              :key="'contacts#part#'+partIdx+'text#'+textIdx"
              v-html="text")
</template>

<script>
import TheHeader from '@/components/TheHeader';

export default {
  components: { TheHeader }
};
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 38px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 30px;
    margin-top: 20px;
  }

  ul { padding-left: 40px !important; }
</style>
